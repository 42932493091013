/* eslint-disable react-hooks/exhaustive-deps */
import {
  FormControl,
  FormLabel,
  HStack,
  Textarea,
  Button,
  VStack,
  Text,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { useAuthContext } from '../../context/auth.context'
import { useLanguageContext } from '../../context/locale.context'
import { useAddTimelineUpdate } from '../../hooks/activity.hooks'
import { useGetTranslations } from '../../hooks/dashboard.hooks'
import { localizationConst } from '../../utils/action.constant'
import { formatNoteUpdateDate, getCurrentDateUTC } from '../../utils/date.utils'
import { useParams } from 'react-router-dom'

function EmailReply() {
  const { params } = useParams()
  const [reply, setReply] = useState('')
  const [error, setError] = useState(null)
  const [issubmitted, setSubmitted] = useState(false)
  const { mutate: loadLanguageTexts } = useGetTranslations()
  const { mutate: mutateUpdateAddTimeline } = useAddTimelineUpdate()
  const userData = !!params ? JSON.parse(atob(params)) : {}

  const {
    state: { locale, selectedLanguage },
    dispatch: languageDispatch,
  } = useLanguageContext()
  const {
    state: { authData },
  } = useAuthContext()
  useEffect(() => {
    Object.keys(locale).length === 0 &&
      setSelectedLanguage({ label: 'English', value: 1 })
  }, [locale, authData])

  const setSelectedLanguage = selectedLanguage => {
    loadLanguageTexts(
      {
        asset_id: authData?.asset_id,
        asset_token_auth: authData?.asset_token_auth,
        language_id: selectedLanguage.value,
        limit_value: 600,
        start_from: 0,
      },
      {
        onSuccess: data => {
          if (!!data)
            languageDispatch({
              type: localizationConst.SET_SELECTED_LANGUAGE,
              selectedLanguage,
              data,
            })
        },
        onError: err => { },
      }
    )
  }

  const buildTimelineEntryParams = () => {
    let attachmentsArr = []
    let activityTimelineJson = {}
    activityTimelineJson = {
      content: reply,
      subject: 'Note - ' + formatNoteUpdateDate(),
      mail_body: reply,
      attachments: attachmentsArr,
      activity_reference: [
        {
          activity_title: '',
          activity_id: '',
        },
      ],
      asset_reference: [{}],
      form_approval_field_reference: [],
    }

    const timelineParams = {
      asset_message_counter: 0,
      flag_pin: 0,
      flag_priority: 0,
      flag_offline: 0,
      flag_retry: 0,
      message_unique_id: userData.message_unique_id,
      activity_channel_id: 0,
      activity_channel_category_id: 0,
      track_latitude: '0.0',
      track_longitude: '0.0',
      track_altitude: 0,
      track_gps_datetime: getCurrentDateUTC(),
      track_gps_accuracy: '0',
      track_gps_status: 0,
      service_version: 1.0,
      app_version: 1.0,
      device_os_id: 5,

      //Activity Details
      activity_type_category_id: 'activityCategoryTypeId',
      //activity_type_id: activityType[0].activity_type_id,
      activity_type_id: userData.activity_type_id,
      activity_id: userData.activity_id,
      activity_parent_id: userData.activity_parent_id,
      activity_sub_type_id: -1,

      //timeline entry
      activity_timeline_collection: JSON.stringify(activityTimelineJson),
      activity_stream_type_id: 325,
      timeline_stream_type_id: 325,
      timeline_transaction_id: 58015,
      activity_timeline_text: '',
      activity_timeline_url: '',

      //Owner details
      activity_access_role_id: 27,
      // data_entity_inline: JSON.stringify(activityTimelineJson),
      operating_asset_first_name: authData.operating_asset_name,
      datetime_log: getCurrentDateUTC(),
    }
    return timelineParams
  }

  const sendReply = () => {
    if (!!reply) {
      let timelineParams = buildTimelineEntryParams()
      mutateUpdateAddTimeline(timelineParams, {
        onSuccess: async res => {
          setError(null)
          setReply('')
          setSubmitted(true)
        },
      })
    } else {
      setError('Comment cannot be empty')
      return false
    }
  }

  return (
    <div>
      {issubmitted ? (
        <VStack w="full">
          <FormControl boxShadow="md" borderRadius="md" p={2} mb={2}>
            <HStack alignItems="center" justifyContent="space-between" mb={2}>
              <VStack>
                <FormLabel
                  color={'brand.800'}
                  fontWeight="400"
                  fontSize="md"
                  my={1}
                >
                  {'Your comment has been published. Want to add more comment?'}
                </FormLabel>
              </VStack>
            </HStack>
          </FormControl>
        </VStack>
      ) : (
        <VStack w="full">
          <HStack alignItems="center" justifyContent="space-between" mb={2}>
            <FormControl boxShadow="md" borderRadius="md" p={2} mb={2}>
              <VStack>
                <FormLabel fontWeight="400" fontSize="md" my={1}>
                  {'Your Reply'}
                </FormLabel>
                <Textarea
                  name="reply"
                  placeholder={['Enter Message']}
                  value={reply}
                  onChange={e => setReply(e.target.value)}
                  size={'md'}
                />
                <Text color={'red.500'}>{error}</Text>
              </VStack>
            </FormControl>
          </HStack>
        </VStack>
      )
      }
      {
        issubmitted ? (
          <HStack w="full" justifyContent="flex-end" my={1} p={2}>
            <Button
              variant="solid"
              size="sm"
              borderRadius="md"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              w="100px"
              ml={1}
              onClick={() => {
                setSubmitted(false)
              }}
            >
              {['Add Comment']}
            </Button>
          </HStack>
        ) : (
          <HStack w="full" justifyContent="flex-end" my={1} p={2}>
            <Button
              variant="solid"
              size="sm"
              borderRadius="md"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              w="100px"
              ml={1}
              onClick={() => {
                sendReply()
              }}
            >
              {locale['Reply']}
            </Button>
          </HStack>
        )
      }
    </div >
  )
}

export default EmailReply
