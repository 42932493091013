import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import ReporteeArpScores from '../containers/arpScores/reporteeARPScores'
import SelfARPScores from '../containers/arpScores/selfARPScores'
import { useLanguageContext } from './../context/locale.context'

const ARPScores = () => {
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <>
      <Tabs size="md" variant="enclosed" colorScheme="brand" isLazy>
        <TabList borderColor="brand.800">
          <Tab _focus={{ boxShadow: 'none' }}>{locale['Self']}</Tab>
          <Tab _focus={{ boxShadow: 'none' }}>{locale['Reportees']}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <SelfARPScores />
          </TabPanel>
          <TabPanel>
            <ReporteeArpScores />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  )
}

export default ARPScores
