import React, { useCallback, useEffect, useState } from 'react'
import ProfileData from './profile'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../authConfig'
import {
	Button,
	HStack,
	Text,
	Box,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	FormHelperText,
	useDisclosure,
} from '@chakra-ui/react'
import Card from '../../components/card'
import { useIsAuthenticated } from '@azure/msal-react'
import { sendEmailAttachmentsViaGraph, sendEmailViaGraph } from '../../graph'
import AlertBox from '../../components/AlertBox'

function AzureSendMail() {
	const { instance, accounts } = useMsal()
	const isAuthenticated = useIsAuthenticated()
	const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()
	const [alertMessage, setAlertMessage] = useState('')
	const [mailParams, setMailParams] = useState({
		to: '',
		subject: '',
		message: '',
	})

	useEffect(() => {
		if (!isAuthenticated) {
			handleLogin('popup')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accounts, isAuthenticated])

	const handleLogin = loginType => {
		if (loginType === 'popup') {
			instance.loginPopup(loginRequest).catch(e => {
				console.log(e)
			})
		} else if (loginType === 'redirect') {
			instance.loginRedirect(loginRequest).catch(e => {
				console.log(e)
			})
		}
	}

	const handleLogout = logoutType => {
		if (logoutType === 'popup') {
			instance.logoutPopup({
				postLogoutRedirectUri: '/',
				mainWindowRedirectUri: '/',
			})
		} else if (logoutType === 'redirect') {
			instance.logoutRedirect({
				postLogoutRedirectUri: '/',
			})
		}
	}
	const customClose = () => {
		alertClose()
		// loadAllForms();
	}

	const sendMail = useCallback(() => {
		instance
			.acquireTokenSilent({
				...loginRequest,
				account: accounts[0],
			})
			.then(response => {
				
					if(!!sendEmailAttachmentsViaGraph(response.accessToken, mailParams)){
						
						setAlertMessage('E-mail sent successfully!')
						onOpen()
						setMailParams({
							to: '',
							subject: '',
							message: '',
						})
					}
			})
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instance, accounts, mailParams])

	return (
		<>
			<Card>
				<HStack justifyContent={'space-between'}>
					<Text style={{ fontWeight: 'bold' }}>
						{' '}
						Microsoft Identity Platform
					</Text>
					{isAuthenticated ? null : (
						<Button
							borderRadius='sm'
							size='sm'
							variant='solid'
							colorScheme='green'
							onClick={() => {
								handleLogin('popup')
							}}
						>
							Sign In
						</Button>
					)}
				</HStack>
			</Card>

			{isAuthenticated && (
				<>
					<Box my={5}>
						<Card>
							<ProfileData />
						</Card>
					</Box>
					<Box my={5}>
						<Card>
							<FormControl>
								<FormLabel fontSize={'sm'}>To Email:</FormLabel>
								<Input
									type='email'
									value={mailParams?.to}
									onChange={e =>
										setMailParams(m => {
											return {
												...m,
												to: e.target.value,
											}
										})
									}
								/>
								<FormHelperText>
									Use Comma (,) separator for multiple mail ids
								</FormHelperText>
							</FormControl>
							<FormControl my={5}>
								<FormLabel fontSize={'sm'}>Subject:</FormLabel>
								<Input
									type='text'
									value={mailParams?.subject}
									onChange={e =>
										setMailParams(m => {
											return {
												...m,
												subject: e.target.value,
											}
										})
									}
								/>
							</FormControl>
							<FormControl>
								<FormLabel fontSize={'sm'}>Message:</FormLabel>
								<Textarea
									value={mailParams?.message}
									onChange={e =>
										setMailParams(m => {
											return {
												...m,
												message: e.target.value,
											}
										})
									}
								/>
							</FormControl>
							<HStack my={5} justifyContent={'flex-end'}>
								<Button
									borderRadius='sm'
									size='sm'
									variant='solid'
									colorScheme='green'
									onClick={() => sendMail()}
								>
									Send Mail
								</Button>
							</HStack>
						</Card>
					</Box>
				</>
			)}

			<AlertBox
				isOpen={isAlertOpen}
				onClose={customClose}
				Message={alertMessage}
			/>
		</>
	)
}

export default AzureSendMail
