import React, { useEffect } from 'react'
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import VidmDocument from './vidmRepo.page'
import DocumentSearch from '../containers/vidm/document'
import Repository from './repository.page'
import { useAuthContext } from '../context/auth.context'
import { useLanguageContext } from '../context/locale.context'
import { groupBy } from 'lodash'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// } from '../containers/repository/repo.utils'
import { useGetRepositoryList } from '../hooks/documents.hooks'

export default function DocumentsPage() {
	const { mutate: getRepoListMutate, isLoading, data } = useGetRepositoryList()
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()

	useEffect(() => {
		// eventLabelTrack({
		// 	categoryId: 5,
		// 	actionId: 0,
		// 	label: 'Switched To Documents Page',
		// })
	}, [])
	useEffect(() => {
		loadRepository()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const loadRepository = () => {
		getRepoListMutate({
			page_start: 0,
			page_limit: 50,
			flag: 0,
		})
	}

	const groupFields = data && groupBy(data, 'tag_id')
	const groupedArray =
		groupFields &&
		Object.entries(groupFields).map(([tagId, items]) => ({
			tag_id: parseInt(tagId),
			tag_name: items[0]?.tag_name || 'Repository',
			items: items.map(item => ({
				...item,
				tag_id: parseInt(tagId),
			})),
		}))
	const dynamicTabs = groupedArray?.map(group => (
		<Tab key={group.tag_id}>{group.tag_name}</Tab>
	))
	return (
		<Box>
			<Tabs
				isLazy
				onChange={index => {
					// eventLabelTrack({
					// 	categoryId: 5,
					// 	actionId: index,
					// 	label: 'Switched To Documents Page',
					// })
					switch (index) {
						case 0:
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Document Repository',
							// 	buttonName: 'Repository',
							// })
							break
						case 1:
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Document Repository',
							// 	buttonName: 'Vidm Document',
							// })
							break
						case 2:
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Document Repository',
							// 	buttonName: 'Document',
							// })
							break
						default:
							break
					}
				}}
				variant='solid'
			>
				<TabList>
					{dynamicTabs}
					{authData.organization_id === 868 ? (
						<>
							<Tab>{locale['Vidm Document']}</Tab>
							<Tab>{locale['Document']}</Tab>
						</>
					) : null}
				</TabList>

				<TabPanels>
					{groupedArray?.map((group, index) => (
						<TabPanel key={index}>
							<Repository data={group.items} />
						</TabPanel>
					))}
					<TabPanel>
						<VidmDocument />
					</TabPanel>
					<TabPanel>
						<DocumentSearch />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	)
}
