import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export default function ChatPage(props) {
  return (
    <Box>
      <Text> Chat page </Text>
    </Box>
  );
}
