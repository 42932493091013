/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useParams, Redirect } from 'react-router-dom'
import { SharedContextProvider } from '../../context/shared.context'
import { SmartFormContextProvider } from '../../context/smart-form.context'
import SmartFormPanel from '../../containers/form-panel/smart-form-panel'
import { setLocalAuthDetails } from '../../utils/common.util'
import { IS_EXTERNAL } from '../../utils/constant'
import { useEmailContext } from '../../routes/email/email.route'
import {
	useLoadFormFieldList,
	useLoadAllFormList,
} from '../../hooks/form.hooks'
import { useLoadActivityDetails } from '../../hooks/activity.hooks'
import FormEditPanel from './email-form-edit-panel'
import { useLoadS3Creds } from '../../hooks/shared.hooks'
import { useLanguageContext } from '../../context/locale.context'
import { useGetTranslations } from '../../hooks/dashboard.hooks'
import { localizationConst } from '../../utils/action.constant'
import { useAuthContext } from '../../context/auth.context'
import CafCrfModalView from '../../containers/workspace-panel/data-management/caf-crf-view/CafCrfModalView'
import { sortBy, unionBy } from 'lodash'
function EmailFormPreview(props) {
	const context = useEmailContext()
	const urlParams = useParams()
	const [urlData, setUrlData] = useState(null)
	const { mutate, isLoading } = useLoadFormFieldList()
	const { mutate: activityDetails } = useLoadActivityDetails()
	const { mutate: mutateS3Creds } = useLoadS3Creds()
	const { mutate: loadLanguageTexts } = useGetTranslations()
	const { mutate: mutateLoadAllForms, isLoadings } = useLoadAllFormList()
	const [activityData, setActivityData] = useState(null)
	const [mappedForms, setMappedForms] = useState([])
	const [selectedForm, setSelectedForm] = useState(null)
	const [romsActivity, setRomsActivity] = useState(null)
	const {
		state: { authData },
	} = useAuthContext()

	const {
		state: { locale, selectedLanguage },
		dispatch: languageDispatch,
	} = useLanguageContext()

	useEffect(() => {
		try {
			let url = atob(urlParams?.params)
			let parsed = JSON.parse(url)
			if (parsed) {
				if (parsed.url_form_data) {
					setUrlData({
						...JSON.parse(parsed.url_form_data),
						...{ form_name: context.formName, is_external: 'true' },
					})
				} else {
					setUrlData({
						...parsed,
						...{ form_name: context.formName, is_external: 'true' },
					})
				}
				context.setOrgId(parsed.organization_id)
				mutateS3Creds()
			}
		} catch (error) {
			console.log(error)
		}
		return () => {}
	}, [urlParams, context.formName])

	useEffect(() => {
		Object.keys(locale).length === 0 &&
			setSelectedLanguage({ label: 'English', value: 1 })
	}, [locale, authData])

	const setSelectedLanguage = selectedLanguage => {
		loadLanguageTexts(
			{
				asset_id: authData?.asset_id,
				asset_token_auth: authData?.asset_token_auth,
				language_id: selectedLanguage.value,
				limit_value: 600,
				start_from: 0,
			},
			{
				onSuccess: data => {
					if (!!data)
						languageDispatch({
							type: localizationConst.SET_SELECTED_LANGUAGE,
							selectedLanguage,
							data,
						})
				},
				onError: err => {},
			}
		)
	}

	useEffect(() => {
		if (urlData && authData && !!urlData.form_id) {
			mutate(
				{
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: urlData.form_id,
				},
				{
					onSuccess: async data => {
						if (data && data[0]) {
							if (data[0].form_name) {
								context.setFormName(data[0].form_name)
							}
						}
					},
				}
			)
		}
	}, [urlData, authData])

	const loadAllForms = () => {
		mutateLoadAllForms(
			{
				statusBasedForms: {
					activity_status_id: !!urlData?.parent_status_id
						? urlData?.parent_status_id
						: urlData?.activity_status_id,
					workflow_activity_id: urlData?.activity_id,
					activity_type_category_id: urlData?.activity_type_category_id,
					flag: 0,
				},
				submittedForms: {
					flag: 1,
					form_id: urlData?.form_id, // form id should be 0 to load all forms
					activity_id: urlData?.activity_id,
					activity_type_id: urlData?.activity_type_id,
					activity_type_category_id: urlData?.activity_type_id,
				},
			},
			{
				onSuccess: async data => {
					setMappedForms(data)
					const desiredObject = data?.find(
						item => item.form_id === urlData?.form_id
					)
					setSelectedForm(desiredObject)
					if (desiredObject) {
						let rmData = JSON.parse(desiredObject?.form_config_roms)
						setRomsActivity(rmData[urlData?.activity_type_id])
					}
				},
				onError: async err => {
					console.log('Error goes here', err)
				},
			}
		)
	}

	useEffect(() => {
		if (urlData?.digital_form_id) {
			loadAllForms()
		}
		if (urlData?.activity_id && romsActivity) {
			activityDetails(
				{
					activity_id: urlData?.activity_id,
					activity_type_id: urlData?.activity_type_id,
				},
				{
					onSuccess: data => {
						setActivityData(data)
					},
				}
			)
		}
	}, [urlData])

	console.log(romsActivity, 'romsActivity', activityData)
	return (
		<Box
			bg='white'
			w={urlData?.digital_form_id ? '100vw' : '50vw'}
			borderRadius='md'
			p={2}
		>
			<SharedContextProvider>
				<SmartFormContextProvider>
					{urlData &&
					urlData?.digital_form_id &&
					romsActivity &&
					activityData ? (
						<CafCrfModalView
							activity={activityData}
							formData={selectedForm}
							romsActivity={romsActivity}
							submittedForms={mappedForms}
						/>
					) : (
						urlData && (
							<FormEditPanel
								isOpen={true}
								onClose={() => {}}
								activity={{
									activity_id: urlData?.activity_id,
									activity_type_id: urlData?.activity_type_id,
									parent_status_id: urlData?.parent_status_id,
									activity_status_id: urlData?.activity_status_id,
									activity_type_category_id: urlData?.activity_type_category_id,
								}}
								formData={urlData}
								refillForm={() => {}}
								isFormEnable={true}
								height='calc(100vh - 130px)'
							/>
						)
					)}
				</SmartFormContextProvider>
			</SharedContextProvider>
		</Box>
	)
}

export default EmailFormPreview
