import React, { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { callMSGraph } from '../../graph'
import { graphConfig, loginRequest } from '../../authConfig'

const ProfileData = () => {
	const { instance, accounts } = useMsal()
	const [graphData, setGraphData] = useState(null)

	useEffect(() => {
		instance
			.acquireTokenSilent({
				...loginRequest,
				account: accounts[0],
			})
			.then(response => {
				callMSGraph(
					graphConfig.graphMeEndpoint,
					response.accessToken,
					setGraphData
				)
			})
	}, [instance, accounts])

	return (
		<div id='profile-div'>
			<p>
				<strong>First Name: </strong> {graphData?.givenName}
			</p>
			<p>
				<strong>Last Name: </strong> {graphData?.surname}
			</p>
			<p>
				<strong>Email: </strong> {graphData?.userPrincipalName}
			</p>
			<p>
				<strong>Id: </strong> {graphData?.id}
			</p>
		</div>
	)
}

export default ProfileData
