/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useParams, Redirect } from 'react-router-dom'
import { SharedContextProvider } from '../context/shared.context'
import { SmartFormContextProvider } from '../context/smart-form.context'
import SmartFormPanel from '../containers/form-panel/smart-form-panel'
import { setLocalAuthDetails } from '../utils/common.util'
import { IS_EXTERNAL } from '../utils/constant'
import { useEmailContext } from '../routes/email/email.route'
import { localizationConst } from '../utils/action.constant'
import {
	useLoadFormFieldList,
	useFormTransactionCheck,
} from '../hooks/form.hooks'
import { useLoadActivityDetails } from '../hooks/activity.hooks'
import { useLoadS3Creds } from '../hooks/shared.hooks'
import { useGetTranslations } from '../hooks/dashboard.hooks'
import { useLanguageContext } from '../context/locale.context'
import { useAuthContext } from '../context/auth.context'

function EmailFormEntry(props) {
	const context = useEmailContext()
	const urlParams = useParams()
	const [urlData, setUrlData] = useState(null)
	const [activity, setactivity] = useState(null)
	const [dummy, setdummy] = useState(true)
	const { mutate, isLoading } = useLoadFormFieldList()
	const { mutate: mutateTransactionCheck } = useFormTransactionCheck()
	const { mutate: mutateS3Creds } = useLoadS3Creds()
	const { mutate: loadLanguageTexts } = useGetTranslations()
	const { mutate: loadActivityDetails } = useLoadActivityDetails()

	const {
		state: { authData },
	} = useAuthContext()

	const {
		state: { locale, selectedLanguage },
		dispatch: languageDispatch,
	} = useLanguageContext()

	useEffect(() => {
		try {
			let url = atob(urlParams?.params)
			let parsed = JSON.parse(url)
			if (parsed.url_form_data) {
				setUrlData({
					...JSON.parse(parsed.url_form_data),
					...{ form_name: context.formName, is_external: 'true' },
				})
				context.setOrgId(parsed.organization_id)
				checkFormTransaction(JSON.parse(parsed.url_form_data))
				mutateS3Creds()
			}
		} catch (error) {
			console.log(error)
		}
		return () => {}
	}, [urlParams, context.formName])

	useEffect(() => {
		Object.keys(locale).length === 0 &&
			setSelectedLanguage({ label: 'English', value: 1 })
	}, [locale, authData])

	const setSelectedLanguage = selectedLanguage => {
		loadLanguageTexts(
			{
				asset_id: authData?.asset_id,
				asset_token_auth: authData?.asset_token_auth,
				language_id: selectedLanguage.value,
				limit_value: 600,
				start_from: 0,
			},
			{
				onSuccess: data => {
					if (!!data)
						languageDispatch({
							type: localizationConst.SET_SELECTED_LANGUAGE,
							selectedLanguage,
							data,
						})
				},
				onError: err => {},
			}
		)
	}

	const checkFormTransaction = params => {
		mutateTransactionCheck(params, {
			onSuccess: async res => {
				if (!!res && res?.length) {
					const JsonStr = {
						form_transaction_id: res[0].data_form_transaction_id, //Add
						form_id: params.form_id,
						org_id: authData.organization_id, // change formate
						organization_id: authData.organization_id,
						account_id: authData.account_id,
						workforce_id: authData.workforce_id,
						asset_id: authData.asset_id,
						auth_asset_id: authData.asset_id,
						auth_token: authData.asset_token_auth, // change formate
						asset_token_auth: authData.asset_token_auth,
						form_name: res[0].data_form_name, // add this
						activity_id: params.activity_id,
						activity_type_id: params.activity_type_id,
						s3BucketName: params.s3BucketName,
						form_flag_enable_external_link:
							params?.form_flag_enable_external_link,
					}

					const FinalJson = btoa(JSON.stringify(JsonStr))
					let redirectionUrl = '/forms/preview/' + FinalJson
					props.history.push(redirectionUrl)
				}
			},
		})
	}

	useEffect(() => {
		if (urlData && authData && !!urlData.form_id) {
			mutate(
				{
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: urlData.form_id,
				},
				{
					onSuccess: async data => {
						if (data && data[0]) {
							if (data[0].form_name) {
								context.setFormName(data[0].form_name)
							}
						}
					},
				}
			)
		}

		if (urlData && urlData.activity_id) {
			loadActivityDetails(
				{
					activity_id: urlData.activity_id,
					activity_type_id: urlData.activity_type_id,
				},
				{
					onSuccess: async data => {
						setactivity(data)
						setdummy(!dummy)
					},
				}
			)
		}
	}, [urlData, authData])

	let allowForm = urlData?.activity_id ? !!urlData && !!activity : !!urlData
	return (
		<Box bg='white' w={'50vw'} borderRadius='md' p={2}>
			<SharedContextProvider>
				<SmartFormContextProvider>
					{allowForm && (
						<SmartFormPanel
							onClose={() => {
								// setworkspaceMode(WORKSPACE_MODE.LOADER_VIEW);
								// loadDetails(activity);
								// setTimeout(() => {
								//   dispatch({
								//     type: sharedConst.REFRESH_LISTING,
								//   });
								// }, 1000);
								// doRefresh(0);
								alert('Form Saved Successfully')
								checkFormTransaction(urlData)
							}}
							formData={urlData}
							activity={activity}
							handleError={() => {
								console.log('error occurred!')
							}}
							refillView={null}
							isBulkEdit={false}
							height='calc(100vh - 130px)'
							isOnFly={true}
							isFromEmail={true}
						/>
					)}
				</SmartFormContextProvider>
			</SharedContextProvider>
		</Box>
	)
}

export default EmailFormEntry
