/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  Spacer,
  Text,
  VStack,
  Box,
  useToast,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import { BiDownload } from 'react-icons/bi'
import {
  useLoadFormFieldListData,
  useLoadFormAttachments,
  useLoadFieldHistory,
  useLoadWorkFlowList,
} from '../../hooks/form.hooks'
import {
  useSubmitForm,
  useLoadActivityStatusList,
  useLoadActivityTypeList,
  useLoadActivityParticipant,
} from '../../hooks/activity.hooks'
import { useAuthContext } from '../../context/auth.context'
import { useSharedContext } from '../../context/shared.context'
import { useSmartFormContext } from '../../context/smart-form.context'
import { sharedConst, smartFormConst } from '../../utils/action.constant'
import {
  prepareFormSaveParams,
  prepareFinalFieldValueForEdit,
} from '../../utils/form.util'
import { downloadAwsFile } from '../../utils/aws.util'
import {
  toSnakeCase,
  formatDDMMYYYYHHMM,
  formatLocalDDMMYYYYHHMM,
  formatDDMMMYYYY,
  getLocalTimeDate,
} from '../../utils/common.util'
import '../../containers/form-edit-panel/style.scss'
import FormEditView from '../../containers/form-edit-panel/edit-view'
import { sectionDetailedTrack } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../../containers/repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const FormEditPanel = ({
  isOpen,
  formData,
  activity,
  onClose,
  isOrigin = false,
  refillForm,
  isFormEnable,
  height,
}) => {
  const [fieldList, setfieldList] = useState([])
  const [refillVal, setRefillVal] = useState(null)
  const [isSmartForm, setIsSmartForm] = useState(null)
  const [isEditable, setIsEditable] = useState(null)
  const [openModal, setModal] = useState(false)
  const [activityType, setActivityType] = useState(null)
  const [activityStatus, setActivityStatus] = useState(null)
  const [hFieldName, setHFieldName] = useState('')
  const [historyData, setHistoryData] = useState([])
  const { mutate, isLoading } = useLoadFormFieldListData()
  const { mutate: mutateSubmitForm } = useSubmitForm()
  const { mutate: mutateActivityTypes } = useLoadActivityTypeList()
  const { mutate: mutateActivityStatus } = useLoadActivityStatusList()
  const { mutate: mutateLoadAttachments } = useLoadFormAttachments()
  const { mutate: mutateLoadFieldHistory } = useLoadFieldHistory()
  const { mutate: mutateLoadParticipants } = useLoadActivityParticipant()
  const { mutate: loadWorkFlowList } = useLoadWorkFlowList()
  const {
    isOpen: isHistoryOpen,
    onOpen: onHistoryOpen,
    onClose: onHistoryClose,
  } = useDisclosure()
  const {
    state: { authData },
  } = useAuthContext()
  const {
    state: { locale },
  } = useLanguageContext()
  const {
    state: { fields },
    dispatch,
  } = useSmartFormContext()

  const { dispatch: sharedDispatch } = useSharedContext()

  const toast = useToast()
  const [docsFields, setdocsFields] = useState([])
  const [participantList, setparticipants] = useState([])
  const {
    activity_id,
    activity_type_id,
    parent_status_id,
    activity_status_id,
    activity_type_category_id,
  } = activity

  const loadParticipants = useCallback(params => {
    mutateLoadParticipants(
      { ...params },
      {
        onSuccess: async data => {
          setparticipants(data ? data.list : [])
        },
      }
    )
  })

  const onDownload = file => {
    if (!!file) downloadAwsFile(file)
  }

  useEffect(() => {
    fetchFieldListData()
    loadParticipants({ activity_id: activity.activity_id })
    loadWorkFlowList(
      {
        statusBasedForms: {
          activity_status_id: parent_status_id || activity_status_id,
          workflow_activity_id: activity.activity_id,
          activity_type_category_id,
          flag: 0,
        },
        submittedForms: {
          flag: 1,
          form_id: 0, // form id should be 0 to load all forms
          activity_id,
          activity_type_id,
          activity_type_category_id,
        },
      },
      {
        onSuccess: async data => {
          if (data && data?.length) {
            let findData = data.find(val => val.form_id === formData.form_id)
            setRefillVal(findData.form_flag_disable_rollback_refill)
          }
        },
      }
    )
  }, [formData, activity])

  useEffect(() => {
    if (!!fields[formData.form_id]) {
      if (Object.keys(fields[formData.form_id]).length > 0) {
        let attachmentFields = Object.values(fields[formData.form_id]).filter(
          item =>
            !!item.final?.field_data_type_category_id &&
            [11, 12, 13].includes(item.final.field_data_type_category_id) &&
            !!item.field_value
        )
        setdocsFields(attachmentFields)
      }
    }
  }, [fields])

  const fetchFieldListData = () => {
    mutate(
      {
        fieldList: {
          organization_id: formData.organization_id || authData.organization_id,
          account_id: formData.account_id || authData.account_id,
          workforce_id: formData.workforce_id || authData.workforce_id,
          form_id: formData.form_id,
        },
        fieldValues: {
          form_id: formData.form_id,
          form_transaction_id: formData.form_transaction_id
            ? formData.form_transaction_id
            : formData.data_form_transaction_id,
          start_from: 0,
          limit_value: 50,
        },
        is_from_timeline: formData.is_from_timeline,
        submitted_data: formData.submitted_data,
      },
      {
        onSuccess: async data => {
          const isSmart = !!data.map(i => i.next_field_id).filter(Boolean)
            .length
          const isEditable = !!data
            .map(i => i.field_value_edit_enabled)
            .filter(Boolean).length
          setIsSmartForm(!!isSmart)
          setIsEditable(isEditable)
          let finalFields = data
          if (!!isSmart) {
            finalFields = data.filter(f => !!f.data)
          }
          setfieldList(finalFields)
          let fields = {}
          fields[formData.form_id] = {}
          finalFields.forEach(item => {
            if (!!item.data) {
              fields[formData.form_id][`${item.field_id}`] = item.data
            }
          })
          dispatch({
            type: smartFormConst.SET_FIELD_VALUES,
            formId: formData.form_id,
            fields,
          })
        },
        onError: async err => {
          console.log('error occurred while loading fields', err)
        },
      }
    )
    mutateActivityTypes(
      { catId: 9 },
      {
        onSuccess: async data => {
          setActivityType(data)
        },
      }
    )
    mutateActivityStatus(
      { catId: 9 },
      {
        onSuccess: async data => {
          setActivityStatus(data)
        },
      }
    )
  }

  const fetchHistory = params => {
    mutateLoadFieldHistory(
      {
        ...params,
      },
      {
        onSuccess: async data => {
          setHistoryData(data)
        },
        onError: async err => {
          console.log(err, 'error')
        },
      }
    )
  }

  const onShowHistory = field => {
    let { field_id, form_id } = field
    let form_transaction_id = formData.form_transaction_id
      ? formData.form_transaction_id
      : formData.data_form_transaction_id
    setHFieldName(field.field_name)
    onHistoryOpen()
    fetchHistory({
      field_id,
      form_id,
      form_transaction_id,
      activity_id: activity.activity_id,
    })
  }

  const { disabledProperty, isFormDisabled, operating_asset_id } = formData
  const { activity_master_data } = activity
  let form_fill_request
  let delegateParticipant = []
  let formId = !!formData ? formData.form_id : ''
  if (!!activity_master_data) {
    form_fill_request = JSON.parse(activity_master_data).form_fill_request
    if (!!form_fill_request) {
      delegateParticipant = form_fill_request.filter(item => {
        return item.form_id === formId
      })
    }
  }
  let isOwner
  if (!!authData && authData.asset_flag_super_admin) {
    isOwner = !!authData.asset_flag_super_admin
  } else {
    isOwner =
      participantList &&
      participantList.find(participant => {
        return (
          participant.asset_id === authData.asset_id &&
          participant.asset_flag_is_owner
        )
      })
  }
  const isMyForm = operating_asset_id === authData.operating_asset_id
  let isRollBackAccess = delegateParticipant.findIndex(
    user => user.asset_id === authData.asset_id
  )
  const disable_actions =
    isSmartForm !== null &&
    (isRollBackAccess !== -1 || isOwner || isMyForm) &&
    !formData.isCompleted &&
    isEditable

  const isRefillDisable = val => {
    if (val === 'rollback') {
      if (refillVal === 1 || refillVal === 3) return true
    } else if (val === 'refill') {
      if (refillVal === 2 || refillVal === 3) return true
    }
  }

  return (
    <VStack
      className={`smart-form-panel-confirm-container`}
      w="full"
      justifyContent="flex-start"
      px={4}
      height={height}
    >
      {!!formData ? (
        <HStack my={4} w="full" p={2} mx={4} bg="secondary" maxH="70px">
          <Text fontSize="md" fontWeight="600" textAlign="center">
            {formData.form_name || ''}
          </Text>
        </HStack>
      ) : null}
      {isLoading && fieldList.length === 0
        ? [1, 2, 3, 4].map(i => {
            return <Skeleton w="full" h="70px" key={i} />
          })
        : null}
      <Box flex={1} w="full" overflowY="scroll" p={2}>
        <FormEditView
          isFormEnable={isFormEnable}
          fieldList={fieldList}
          formData={formData}
          activity={activity}
          setFieldList={() => fetchFieldListData()}
          onShowHistory={onShowHistory}
          isSmartForm={isSmartForm}
          disable_actions={disable_actions}
        />
      </Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isHistoryOpen}
        onClose={onHistoryClose}
      >
        <ModalOverlay />
        <ModalContent
          borderRadius="md"
          bg="white"
          minW="35vw"
          m="auto"
          h="500px"
        >
          <ModalHeader fontSize="lg" fontWeight="400">
            {hFieldName}
          </ModalHeader>
          <ModalCloseButton zIndex="100" />
          <Divider />
          <ModalBody
            p={0}
            w="100%"
            position="relative"
            className={'field-history-container'}
          >
            <div className="field-history-body">
              {historyData.length > 0 ? (
                historyData.reverse().map((fieldItem, key) => {
                  let updatedFieldValue = ''
                  switch (fieldItem.data_type_category_id) {
                    case 1:
                      if (fieldItem.data_type_id === 4) {
                        updatedFieldValue = formatLocalDDMMYYYYHHMM(
                          fieldItem.data_entity_datetime_1
                        )
                      } else {
                        updatedFieldValue = formatDDMMMYYYY(
                          fieldItem.data_entity_date_1
                        )
                      }
                      break
                    case 2:
                      if (fieldItem.data_type_id === 6) {
                        updatedFieldValue = fieldItem.data_entity_double_1
                      } else {
                        updatedFieldValue = fieldItem.data_entity_bigint_1
                      }
                      break
                    case 6:
                      if (fieldItem.data_type_id === 18) {
                        if (fieldItem.data_entity_inline === '{}') {
                          updatedFieldValue = fieldItem.data_entity_decimal_1
                        } else {
                          updatedFieldValue = fieldItem.data_entity_inline
                        }
                      } else {
                        updatedFieldValue = fieldItem.data_entity_decimal_1
                      }
                      break
                    case 4:
                      if (fieldItem.data_type_id === 59) {
                        updatedFieldValue = fieldItem.data_entity_text_1
                      } else if (fieldItem.data_type_id === 68) {
                        if (fieldItem.data_entity_inline !== '{}') {
                          updatedFieldValue =
                            !!JSON.parse(fieldItem.data_entity_inline)[0] &&
                            !!JSON.parse(fieldItem.data_entity_inline)[0]
                              .workflow_activity_title
                              ? JSON.parse(fieldItem.data_entity_inline)
                                  .map(data => data.workflow_activity_title)
                                  .join(', ')
                              : ''
                        }
                      } else if (fieldItem.data_type_id === 57) {
                        let valSplit = fieldItem.data_entity_text_1.split('|')
                        updatedFieldValue = !!valSplit[1] ? valSplit[1] : ''
                      } else if (fieldItem.data_type_id === 71) {
                        if (fieldItem.data_entity_inline !== '{}') {
                          let product_data = JSON.parse(
                            fieldItem.data_entity_inline
                          )
                          updatedFieldValue = (
                            <div className="cart-history">
                              <p>
                                {locale['Product Category']}:{' '}
                                <strong>{product_data.product_tag_name}</strong>
                              </p>
                              <p>
                                {locale['Product Family']}:{' '}
                                <strong>
                                  {product_data.product_activity_type_name}
                                </strong>
                              </p>
                              <p>
                                {locale['Product']}:{' '}
                                <strong>
                                  {product_data.product_activity_title}
                                </strong>
                              </p>
                              <p>
                                {locale['Variant']}:{' '}
                                <table border="1" width="100%">
                                  <thead>
                                    <tr>
                                      <td>{locale['Name']}</td>
                                      <td>{locale['Quantity']}</td>
                                      <td>{locale['Price']}</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!!product_data.cart_items &&
                                      product_data.cart_items.map(
                                        (variant, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                {
                                                  variant.product_variant_activity_title
                                                }
                                              </td>
                                              <td>
                                                {
                                                  variant.product_variant_quantity
                                                }
                                              </td>
                                              <td>
                                                {
                                                  variant.product_variant_unit_price
                                                }
                                              </td>
                                            </tr>
                                          )
                                        }
                                      )}
                                  </tbody>
                                </table>
                              </p>
                              <p>
                                {locale['Cart Total']}:{' '}
                                <strong>{product_data.cart_total_cost}</strong>
                              </p>
                            </div>
                          )
                        }
                      } else if (fieldItem.data_type_id === 69) {
                        if (fieldItem.data_entity_inline !== '{}') {
                          let asset = JSON.parse(fieldItem.data_entity_inline)
                          updatedFieldValue = !!asset
                            ? asset
                                .map(data => data.operating_asset_first_name)
                                .join(',')
                            : ''
                        }
                      } else {
                        updatedFieldValue = fieldItem.data_entity_bigint_1
                      }
                      break
                    case 7:
                      if (fieldItem.data_type_id === 20) {
                        updatedFieldValue = fieldItem.data_entity_text_2
                      } else if (fieldItem.data_type_id === 64) {
                        let address =
                          typeof fieldItem.data_entity_inline === 'string' &&
                          fieldItem.data_entity_inline !== '{}'
                            ? JSON.parse(fieldItem.data_entity_inline)
                            : {}
                        updatedFieldValue = (
                          <div className="cart-history">
                            {!!Object.keys(address).length &&
                              Object.keys(address).map((a, i) => {
                                return (
                                  <p key={i}>
                                    {a + `: `}
                                    <strong>{address[a]}</strong>
                                  </p>
                                )
                              })}
                          </div>
                        )
                      } else {
                        updatedFieldValue = fieldItem.data_entity_text_1
                      }
                      break
                    case 10:
                      if (fieldItem.data_type_id === 6) {
                        updatedFieldValue = fieldItem.data_entity_double_1
                      } else {
                        updatedFieldValue = fieldItem.data_entity_text_1
                      }
                      break
                    case 11:
                      updatedFieldValue = (
                        <a
                          style={{
                            color: 'blue',
                            marginLeft: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            sectionDetailedTrack({
                              category: GA_CATEGORY_DIALOG_BOX,
                              action: 'Form Edit Panel',
                              label: 'Download',
                            })
                            onDownload(fieldItem.data_entity_text_1)
                          }}
                        >
                          {fieldItem.data_entity_text_1.split('/').slice(-1)}
                        </a>
                      )
                      break
                    case 13:
                      if (fieldItem.data_type_id === 65) {
                        if (fieldItem.data_entity_inline !== '{}') {
                          let contact_data = JSON.parse(
                            fieldItem.data_entity_inline
                          )
                          updatedFieldValue = (
                            <div className="cart-history">
                              {!!Object.keys(contact_data).length &&
                                Object.keys(contact_data).map((a, i) => {
                                  return (
                                    <p key={i}>
                                      {a + `: `}
                                      <strong>
                                        {typeof contact_data[a] === 'string'
                                          ? contact_data[a]
                                          : !!Object.keys(contact_data[a])
                                              .length &&
                                            Object.keys(contact_data[a]).map(
                                              (b, i) => {
                                                return (
                                                  <p
                                                    key={a + i}
                                                    style={{
                                                      marginLeft: '20px',
                                                    }}
                                                  >
                                                    {b + `: `}
                                                    <strong>
                                                      {typeof contact_data[a][
                                                        b
                                                      ] === 'string'
                                                        ? contact_data[a][b]
                                                        : ''}
                                                    </strong>
                                                  </p>
                                                )
                                              }
                                            )}
                                      </strong>
                                    </p>
                                  )
                                })}
                            </div>
                          )
                        }
                      } else if (fieldItem.data_type_id === 76) {
                        if (
                          fieldItem.data_entity_text_1 !== '[]' &&
                          fieldItem.data_entity_text_1 !== ''
                        ) {
                          let dropbox = JSON.parse(fieldItem.data_entity_text_1)
                          updatedFieldValue = dropbox.map((d, i) => {
                            return (
                              <div key={i}>
                                <a
                                  style={{
                                    color: 'blue',
                                    marginLeft: '10px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    sectionDetailedTrack({
                                      category: GA_CATEGORY_DIALOG_BOX,
                                      action: 'Form Edit Panel',
                                      label: 'Download',
                                    })
                                    onDownload(d.file_url)
                                  }}
                                >
                                  {d.file_name}
                                </a>
                              </div>
                            )
                          })
                        }
                      } else {
                        updatedFieldValue = (
                          <a
                            style={{
                              color: 'blue',
                              marginLeft: '10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              sectionDetailedTrack({
                                category: GA_CATEGORY_DIALOG_BOX,
                                action: 'Form Edit Panel',
                                label: 'Download',
                              })

                              onDownload(fieldItem.data_entity_text_1)
                            }}
                          >
                            {fieldItem.data_entity_text_1.split('/').slice(-1)}
                          </a>
                        )
                      }
                      break
                    default:
                      updatedFieldValue = fieldItem.data_entity_text_1
                      break
                  }
                  return (
                    <div
                      className="field-entry-item"
                      key={fieldItem.field_id + key}
                    >
                      {fieldItem.data_type_category_id !== 13 ? (
                        <p>
                          {locale['Value updated to']}
                          <strong> {updatedFieldValue} </strong>
                        </p>
                      ) : (
                        <p>
                          {locale['Updated a doc to']}
                          <div>{updatedFieldValue}</div>
                        </p>
                      )}

                      <small className="text-muted">
                        <i>
                          {fieldItem.operating_asset_first_name}
                          {fieldItem.asset_first_name
                            ? ' (' + fieldItem.asset_first_name + ')'
                            : '(' + fieldItem.operating_asset_type_name + ') '}
                          at {getLocalTimeDate(fieldItem.log_datetime)}
                        </i>
                      </small>
                    </div>
                  )
                })
              ) : (
                <div className="loader">
                  <p>{locale['Loading...']}</p>
                </div>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

export default FormEditPanel
