/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Collapse,
	Flex,
	Icon,
	IconButton,
	Tooltip,
	useDisclosure,
	VStack,
	useColorMode,
	useColorModeValue,
} from '@chakra-ui/react'
import React, { useEffect, useState, useRef } from 'react'
import { MdSort, MdDownload } from 'react-icons/md'
import Scrollbar from 'react-custom-scrollbars-2'
import FilterContainer from '../containers/dashboard/filter-view'
import WidgetData from '../containers/dashboard/widget-workspace/widget-data'
import WidgetView from '../containers/dashboard/widget-workspace/widget-view'
import WidgetCardData from '../containers/dashboard/widget-workspace/widget-card-view'
import WorkspacePanel from '../containers/workspace-panel/workspace-panel'
import { DashboardContextProvider } from '../context/dashboard.context'
import { DrilldownContextProvider } from '../context/drilldown.context'
import { WIDGET_WORKSPACE_MODE } from '../utils/widget-constants'
import { dashboardConst } from '../utils/action.constant'
import { useAuthContext } from '../context/auth.context'
import { sectionDetailedTrack } from '../containers/repository/repo.utils'
import { GA_CATEGORY_DASHBOARD } from './../containers/repository/repo.utils'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import { RiAddFill } from 'react-icons/ri'
import { SmartFormContextProvider } from '../context/smart-form.context'

export default function DashboardPage(props) {
	const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
	const [view, setView] = useState(WIDGET_WORKSPACE_MODE.WIDGET_VIEW)
	const [isClickArrow, setIsClickArrow] = useState(false)
	const {
		state: { authData },
		dispatch,
	} = useAuthContext()

	const downloadRef = React.useRef()

	useEffect(() => {
		if (
			(view === WIDGET_WORKSPACE_MODE.WIDGET_DATA ||
				view === WIDGET_WORKSPACE_MODE.WIDGET_CARD_DATA) &&
			!!isOpen
		) {
			onToggle()
		}
	}, [view])

	const onScrollView = async e => {
		let bottom = e.scrollHeight - e.scrollTop - e.clientHeight <= 1
		if (bottom) {
			dispatch({ type: dashboardConst.SCROLL_BOTTOM })
		}
	}

	return (
		<DashboardContextProvider>
			<DrilldownContextProvider>
				<Flex w='100%'>
					<VStack h='calc(100vh - 92px)' w='100%' align='stretch' mr={3}>
						<Collapse
							in={isOpen}
							endingHeight='auto'
							animateOpacity
							style={{
								overflow: 'unset',
							}}
						>
							<FilterContainer widgetView={view} onWidgetViewChange={setView} />
						</Collapse>
						<Box h='100%'>
							<Scrollbar onScroll={e => onScrollView(e)}>
								<div ref={downloadRef}>
									{view === WIDGET_WORKSPACE_MODE.WIDGET_VIEW ? (
										<WidgetView
											onWidgetViewChange={setView}
											ref={downloadRef}
										/>
									) : view === WIDGET_WORKSPACE_MODE.WIDGET_DATA ? (
										<SmartFormContextProvider>
											<WidgetData
												onWidgetViewChange={setView}
												onToggle={onToggle}
												setIsClickArrow={setIsClickArrow} //simha
												isClickArrow={isClickArrow}
											/>
										</SmartFormContextProvider>
									) : view === WIDGET_WORKSPACE_MODE.WIDGET_CARD_DATA ? (
										<WidgetCardData
											onWidgetViewChange={setView}
											onToggle={onToggle}
											setIsClickArrow={setIsClickArrow}
											isClickArrow={isClickArrow}
										/>
									) : view === WIDGET_WORKSPACE_MODE.CARD_WIDGET_WORKSPACE ? (
										<WorkspacePanel
											isDashboard={true}
											is_card_view={true}
											onWidgetViewChange={setView}
											setIsClickArrow={setIsClickArrow}
											isClickArrow={isClickArrow}
										/>
									) : (
										<WorkspacePanel
											isDashboard={true}
											is_card_view={false}
											onWidgetViewChange={setView}
											setIsClickArrow={setIsClickArrow}
											isClickArrow={isClickArrow}
										/>
									)}
								</div>
							</Scrollbar>
						</Box>
					</VStack>
					<VStack>
						<IconButton
							bg='white'
							borderRadius='md'
							boxShadow='md'
							aria-label='Refresh'
							cursor='pointer'
							size='sm'
							display={
								[
									WIDGET_WORKSPACE_MODE.WIDGET_VIEW,
									// WIDGET_WORKSPACE_MODE.WIDGET_CARD_DATA,
									// WIDGET_WORKSPACE_MODE.WIDGET_DATA,
								].includes(view)
									? 'block'
									: 'none'
							}
							_focus={{
								bg: 'secondary',
							}}
							active={isOpen}
							_active={{
								bg: 'secondary',
							}}
							_hover={{
								bg: 'secondary',
							}}
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_DASHBOARD,
									action: 'Toggle Filters',
								})
								onToggle()
							}}
							icon={<Icon as={MdSort} w={4} h={4} />}
						/>{' '}
						<ReactToPrint content={() => downloadRef?.current}>
							<PrintContextConsumer>
								{({ handlePrint }) => (
									<IconButton
										bg='white'
										borderRadius='md'
										boxShadow='md'
										aria-label='Refresh'
										cursor='pointer'
										size='sm'
										display={
											view === WIDGET_WORKSPACE_MODE.WIDGET_VIEW
												? 'block'
												: 'none'
										}
										_focus={{
											bg: 'secondary',
										}}
										active={isOpen}
										_active={{
											bg: 'secondary',
										}}
										_hover={{
											bg: 'secondary',
										}}
										onClick={e => {
											handlePrint(e)
										}}
										icon={<Icon as={MdDownload} w={4} h={4} />}
									/>
								)}
							</PrintContextConsumer>
						</ReactToPrint>
					</VStack>
				</Flex>
			</DrilldownContextProvider>
		</DashboardContextProvider>
	)
}
